import "../css/planInformation.css"
import GeneralLayout from "../Layouts/GeneralLayout"
import CardBenefits from "../components/CardBenefits"
import Card from "../components/Card"
import Star from "../assets/star.svg"
import { Button } from "../components/Button"
import { useNavigate } from "react-router-dom"
import { firestore, userCollection } from "../services/firebaseConfig"
import { collection, query, where, getDocs } from "firebase/firestore";



export default function PlanInformation() {

    const navigate = useNavigate()

    async function redirect() {
        const uid = localStorage.getItem('uid');

        if (uid) {
            const userQuery = query(collection(firestore, userCollection), where("uid", "==", uid));

            try {
                const userQuerySnapshot = await getDocs(userQuery);

                if (!userQuerySnapshot.empty) {
                    userQuerySnapshot.forEach((doc) => {
                        console.log(doc.id, " => ", doc.data());
                        const userData = doc.data();

                        if (!userData.cnpj) {
                            navigate(`/form-mei/${doc.id}`);
                        } else if (!userData.account_level) {
                            navigate(`/account-level/${doc.id}`);
                        } else if (!userData.gov_password) {
                            navigate(`/gov-password/${doc.id}`);
                        } else if (!userData.mei_regulation) {
                            navigate(`/regularization/${doc.id}`);
                        } else {
                            navigate(`/indicacao/${doc.id}`);
                        }
                    });
                } else {
                    localStorage.removeItem('uid');
                    console.log("Nenhum documento encontrado para o UID fornecido.");
                }
            } catch (error) {
                console.error('Erro ao buscar usuário: ', error);
            }
        } else {
            navigate('/form-data');
        }
    }

    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <div className='plan-information__title'>
                    <p className='plan-information__summary'>O MotoMei é um programa que auxilia os entregadores a abrir seu CNPJ e emitir notas fiscais mensais*</p>

                </div>

                <div className='plan-information__benefits'>
                    <h2 className='plan-information__benefits-title'>Confira os benefícios:</h2>

                    <div className="plan-information__benefits-list">
                        <div className="benefits-list__group">
                            <CardBenefits text='Regularização do MEI' />
                            <CardBenefits text='Comprovação de renda facilitada' />
                            <CardBenefits text='Contribuição para aposentadoria' />
                        </div>
                        <div className="benefits-list__group">
                            <CardBenefits text='Enviamos mensalmente a guia do seu MEI' />
                            <CardBenefits text='Descontos em planos de saúde e seguros' />
                            <CardBenefits text='Auxílio do INSS em caso de doença ou invalidez' />
                        </div>
                    </div>
                </div>
            </GeneralLayout>
            <Button name="Eu quero" onClick={redirect} />
        </div>
    )
}