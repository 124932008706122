import '../css/termsUse.css'
import arrow from '../assets/arrow.svg'
import GeneralLayout from "../Layouts/GeneralLayout";


export default function TermsUse() {
    return (
        <>
            <div className='wrapperGeneralLayout'>
                <GeneralLayout>
                    <h2 className="terms_title">Termo de Adesão MotoMei</h2>
                    <div className="terms-paragraph">
                        <p className="paragraph_clause">
                            1. <span className="paragraph_clause-title">
                                Resoluções Gerais:
                            </span>
                            Estes termos tem por objetivo esclarecer as regras do programa <strong>MotoMei Contabilidade</strong>.

                        </p>
                        <p className="paragraph_subclause">
                            a. <span className="paragraph_clause-title">Sobre o programa:</span>
                            Esse programa é um serviço, prestado em totalidade pela MotoMei Contabilidade  para oferecer serviços de contabilidade para microempreendedores individuais (MEI), especialmente para entregadores.
                        </p>
                        <p className="paragraph_subclause">
                            b. <span className="paragraph_clause-title">Sobre os serviços oferecidos:</span>
                            Estão compreendidos nos serviços oferecidos pela MotoMei Contabilidade a abertura do CNPJ na modalidade MEI (Micro Empreendedor Individual), a emissão mensal de Notas Fiscais de Serviço em nome do microempreendedor, indicando em sua totalidade de ganhos realizados informado pelo responsável do CNPJ, está incluso nos serviços Motomei: a declaração do MEI, alteração cadastral (caso você solicite alguma atualização ou inclusão de atividade), emissão de nota de prestação de serviços, envio do boleto da guia DAS mensalmente, acompanhamento de faturamento e levantamento de débitos do MEI, e dúvidas gerais sobre contabilidade. Qualquer serviço não descrito acima, poderá ter um custo adicional a ser consultado diretamente com a MotoMei Contabilidade.
                        </p>
                        <p className="paragraph_clause">
                            2. O pagamento da guia DAS que vence todo dia 20 de cada mês é de responsabilidade do microempreendedor individual.
                        </p>
                        <p className="paragraph_clause">
                            3. <span className="paragraph_clause-title">Da aptidão do usuário à participação no programa: </span>
                            Para estar apto para participação no programa o usuário se compromete a fornecer informações verídicas que serão utilizadas apenas para fins próprios do microempreendedor.
                        </p>

                        <p className="paragraph_clause">
                            4. <span className="paragraph_clause-title">Sobre autorização contábil:</span>
                            O usuário autoriza a MotoMei contabilidade a dar entrada e gerenciar seu CNPJ e todo e qualquer procedimento que envolva a gestão do CNPJ do  usuário. Qualquer outro serviço contábil não compreendido no escopo do projeto deve ter autorização prévia, por escrito, do usuário e está sujeito a valores extras, conforme descrito no item 1 parágrafo “b”.
                        </p>

                        <p className="paragraph_clause">
                            5. <span className="paragraph_clause-title">Sobre o valor do imposto devido por categoria:</span>
                            O <strong>MEI comum</strong> paga a Guia DAS com o valor de 5% do
                            salário-mínimo em vigor, somado à quantia de <span className="nobreak">R$ 1,00</span> de ICMS para Comércio e Indústria; ou <span className="nobreak">R$ 5,00</span> para Serviços – ISS; ou <span className="nobreak">R$ 6,00</span> para Comércio e Serviços - ICMS e ISS. A categoria <strong>MEI Caminhoneiro</strong> (caminhões e carretas) paga por mês o valor equivalente a 12% de salário-mínimo somado a <span className="nobreak">R$ 1,00</span> de ICMS e <span className="nobreak">R$ 5,00</span> de ISS.
                        </p>
                        <p className="paragraph_clause">
                            6. <span className="paragraph_clause-title">Sobre os valores do Programa MotoMei: </span>
                            O MotoMei tem um custo MENSAL de <span className="nobreak">R$ 29,90</span>, que será enviado via WhatsApp. Este custo é referente à assessoria contábil e entrega das obrigações do seu MEI.
                        </p>

                        <p className="paragraph_clause">
                            7. <span className="paragraph_clause-title">Sobre cancelamento:</span>
                            O usuário que não desejar mais fazer parte do programa deve, obrigatoriamente, manifestar esse desejo pelo WhatsApp com 30 dias de antecedência à data em que deseja finalizar a parceria, não incidindo ônus ou multa de qualquer natureza ao usuário. Caso não haja esta manifestação, esse usuário continuará ativo e sujeito às cobranças normais do programa por tempo indeterminado. A MotoMei Contabilidade se reserva o direito de encerrar a parceria com qualquer usuário do serviço sem necessidade de justificativa, respeitando apenas um prazo de 30 dias de antecedência à finalização dos serviços prestados de contabilidade.
                        </p>

                        <p className="paragraph_clause">
                            8. <span className="paragraph_clause-title">Sobre autorização de uso dos dados: </span>
                            Ao contratar este serviço o usuário autoriza a MotoMei a solicitar e utilizar os dados necessários à realização dos serviços aqui descritos e de qualquer portal necessário à realização do serviço contábil de emissão de notas fiscais e abertura/consulta do MEI, com o compromisso que esses dados serão guardados e utilizados apenas para este fim específico, se valendo de todas as regras descritas na LGPD (Lei Geral de Proteção de Dados), Lei Federal n.º 13.709/2018.
                        </p>

                        <p className="paragraph_clause">
                            9. <span className="paragraph_clause-title">Sobre direitos reservados:</span>
                            A MotoMei Contabilidade se reserva o direito de alterar qualquer uma das cláusulas descritas anteriormente sem aviso prévio, estando essas condições ativas por tempo indeterminado.
                        </p>

                    </div>
                </GeneralLayout >
            </div >
        </>
    )
}